import { render, staticRenderFns } from "./AccessorialsDrayage.vue?vue&type=template&id=07be0565&scoped=true&"
import script from "./AccessorialsDrayage.vue?vue&type=script&lang=js&"
export * from "./AccessorialsDrayage.vue?vue&type=script&lang=js&"
import style0 from "./AccessorialsDrayage.vue?vue&type=style&index=0&id=07be0565&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07be0565",
  null
  
)

export default component.exports