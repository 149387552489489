<template>
  <div class="operation-selector">
    <label class="operation-selector__title">Type</label>
    <RCSelect
      class="type-operation__dropdown"
      :class="error ? 'missing-field' : ''"
      label="Import/Export"
      v-model="typeValue"
      :suggestions="options"
      :disabled="disabled"
      @input="handleOperationChange"
      :key="renderKey"
    />
  </div>
</template>

<script>
import RCSelect from "@/components/RCComponents/CustomRCSelect.vue";

export default {
  name: "OperationSelector",
  props: {
    disabled: Boolean,
    selectedOperation: String,
  },
  components: {
    RCSelect
  },
  data() {
    return {
      error: false,
      typeValue: null,
      options: [
        { label: "Import", value: "Import" },
        { label: "Export", value: "Export" }
      ],
      renderKey: 0,
    };
  },
  watch: {
    selectedOperation: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.typeValue = value;
        }
        this.renderKey += 1;
      }
    }
  },
  methods: {
    handleOperationChange(value) {
      this.typeValue = value;
      this.$emit('input', value);
      this.error = false;
    },
    validateOperation() {
      this.error = !this.typeValue;
      return !this.error;
    }
  }
};
</script>

<style lang="scss" scoped>
.operation-selector {
  width: 350px;
  @media (max-width: 670px) {
    width: 100%;
  }
  &__title {
    color: #6c757d;
    font-weight: 400;
    font-size: 12px;
    margin: 3px 0 0 5px;
    text-align: left;
    display: flex;
    justify-content: left;
  }
}

.missing-field ::v-deep .rc-select-container {
  background: $missing-fields;
}
</style>
