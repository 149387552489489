<template>
  <div class="drayage-load">
    <b-overlay class="drayage-load-spinner" rounded="sm" :show="loading">
      <div class="drayage-load__content">
        <CustomerSearch v-model="selectedCustomer" :selectedCustomer="selectedCustomer" />
        <OperationSelector
          v-model="selectedOperation"
          ref="operationSelector"
          :selectedOperation="selectedOperation"
        />
        <DrayageStops
          :milesValue="milesValue"
          :chosenLoad="chosenLoad"
          @updateStops="updateStops"
          ref="stopsDrayage"
        />
        <div class="row-content">
          <div class="miles-container">
            <MilesSlider :milesValue="milesValue" v-model="milesValue" ref="milesDrayage" />
          </div>
        </div>
        <AccessorialsDrayage
          :selectedAccessorialsInfo="selectedAccessorials"
          :departmentId="modeInfo.departmentId"
          :quoteMode="modeInfo.id"
          ref="AccessorialDrayage"
        />
        <div class="cargo-value-container">
          <CargoValue :cargoValueLoad="cargoValue" v-model="cargoValue" />
        </div>
        <LineItemsDrayage
          v-model="lineItems"
          :lineItems="selectedLineItems"
          :selectedCarrier="selectedCarrier"
          ref="lineItemDrayage"
        />
        <div class="button-container">
          <button class="button-quote" @click="validateDrayageSchema()">
            {{ !$route.params.loadId ? $t("bookALoad.quote") : $t("bookALoad.quoteSave") }}
          </button>
        </div>
        <Map />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidationUpdateStops from "@/Extend/ValidationUpdateStops";
import ValidationUpdateExpectedDate from "@/Extend/ValidationUpdateExpectedDate";
import AccessorialsDrayage from "./Drayage/AccessorialsDrayage.vue";
import CustomerSearch from "./components/CustomerSearch.vue";
import CargoValue from "./CarrierSelection/loadResume/CargoValue.vue";
import DrayageStops from "./Drayage/DrayageStops.vue";
import LineItemsDrayage from "./Drayage/LineItemsDrayage.vue";
import MilesSlider from "./Drayage/MilesSlider.vue";
import OperationSelector from "./Drayage/OperationSelector.vue";
import Map from "./LoadCreation/map/Map.vue";

export default {
  name: "CreateDrayageLoad",
  components: {
    AccessorialsDrayage,
    CustomerSearch,
    CargoValue,
    DrayageStops,
    LineItemsDrayage,
    MilesSlider,
    OperationSelector,
    Map,
  },
  mixins: [ValidationUpdateStops, ValidationUpdateExpectedDate],

  props: {
    modeInfo: Object,
  },
  data() {
    return {
      loading: false,
      selectedCustomer: null,
      selectedOperation: null,
      options: [
        { label: "Import", value: "Import" },
        { label: "Export", value: "Export" },
      ],
      milesValue: 100,
      cargoValue: null,
      lineItems: {},
      drayageSchema: {
        load: {
          mode_id: null,
          customer_id: null,
        },
        miles: 0,
        type: "Import",
        accessorials: [],
        stops: [
          {
            country_code: "",
            state_code: "",
            city: "",
            zip_code: "",
            label: "",
            location_id: null,
            name: "",
            related_name: "pickup",
            is_pickup: true,
            is_dropoff: false,
            number: 0,
            contact_info_id: null,
          },
          {
            country_code: "",
            state_code: "",
            city: "",
            zip_code: "",
            label: "",
            location_id: null,
            name: "",
            related_name: "delivery",
            is_pickup: false,
            is_dropoff: true,
            number: 1,
          },
        ],
        line_items: {
          type_container: null,
          weight: null,
          weight_units: null,
          commodity: null,
        },
      },
      chosenLoad: null,
      expectedDeliveryDate: "",
      selectedLineItems: [],
      selectedAccessorials: [],
      chosenLoadDrayage: null,
      selectedCarrier: null,
      prevCargoValue: null,
    };
  },
  async created() {
    this.loading = true;
    const { loadId } = this.$route.params;
    this.chosenLoad = this.$store.getters["drayage/getChosenLoad"];
    if (loadId) {
      if (!this.chosenLoad || this.chosenLoad.id !== this.$route.params.loadId) {
        this.chosenLoad = await this.$store.dispatch("load/getLoadById", loadId);
        this.chosenLoadDrayage = await this.$store.dispatch("drayage/getCarriers", { load_id: loadId });
        if (this.chosenLoadDrayage.status === 404) window.history.back();
      }
      if (this.chosenLoad && this.chosenLoadDrayage) {
        this.assignChosenLoadValues(this.chosenLoad, this.chosenLoadDrayage);
        this.prevCargoValue = this.chosenLoad.cargo_value_for_insurance;
      }
    }
    this.loading = false;
    if (!this.$route.params.loadId) this.$store.commit("load/accessorials/resetChosenAccessorials");
  },
  computed: {
    ...mapGetters("drayage", ["getChosenLoad"]),
    ...mapGetters("drayage", ["getPortList"]),
    ...mapGetters({
      hazmatType: "drayage/getHazmatType",
    }),
  },
  methods: {
    ...mapActions("drayage", ["createLoadDrayage"]),
    ...mapActions("drayage", ["updateLoadDrayage"]),

    setHazmatType(hazmatClassDivision) {
      this.hazmatType = hazmatClassDivision;
    },

    assignChosenLoadValues(chosenLoad, chosenLoadDrayage) {
      this.selectedAccessorials = chosenLoad.accessorials;
      this.selectedCustomer = { ...chosenLoad.customer };
      this.expectedDeliveryDate = '';
      this.selectedLineItems = chosenLoad.line_items;
      this.selectedCarrier = chosenLoadDrayage;
      this.milesValue = chosenLoad.miles;
      this.cargoValue = chosenLoad.cargo_value_for_insurance;
      this.selectedOperation = chosenLoad.import_export;
      this.$store.dispatch("drayage/setHazmatType", chosenLoad.line_items[0].hazmat_class_division);
    },
    updateStops(stops) {
      if (stops.pickup) {
        this.drayageSchema.stops[0] = {
          ...this.drayageSchema.stops[0],
          ...stops.pickup
        };
      }
      if (stops.delivery) {
        this.drayageSchema.stops[1] = {
          ...this.drayageSchema.stops[1],
          ...stops.delivery
        };
      }
    },
    async validateDrayageSchema() {
      const validateOperation = this.$refs.operationSelector.validateOperation();
      // 1. Vamos a validar los stops de drayage
      const validateStops = await this.$refs.stopsDrayage.validateFormFields();
      // 2. Vamos a validar las millas sean mayor a 0
      const validateMiles = this.$refs.milesDrayage.validatePositiveNumber();
      // 4. Vamos a validar los line items de drayage
      const validateLineItems = await this.$refs.lineItemDrayage.validateFormfields();
      if (validateOperation && validateLineItems && validateStops && validateMiles) {
        if (!this.$route.params.loadId) await this.createDrayageLoad();
        else await this.updateDrayageLoad();
      }
    },
    async createDrayageLoad() {
      this.loading = true;
      try {
        const accessorialsDrayage = this.$store.getters["load/accessorials/getChosenAccessorials"];
        const payload = {
          load: {
            mode_id: this.modeInfo.modeId,
            customer_id: this.selectedCustomer?.id || process.env.VUE_APP_ACCOUNT_ID_TEST,
            cargo_value_for_insurance: this.cargoValue || 0,
          },
          miles: this.milesValue,
          terminal: this.drayageSchema.stops[0]?.terminal_code || "",
          type: this.selectedOperation,
          accessorials: accessorialsDrayage,
          size: this.lineItems[0].type_container,
          stops: this.drayageSchema.stops?.map((stop, index) => {
            if (index === 1) {
              return {
                ...stop,
                expected_date: null
              };
            }
            return stop;
          }) || [],
          line_item: {
            description: this.lineItems[0].description,
            weight: this.lineItems[0].weight,
            weight_units: this.lineItems[0].weight_units,
          },
        };
        const response = await this.createLoadDrayage({ payload, params: { hazmat_type: this.hazmatType } });
        if (response) {
          if (!response?.data_rates) {
            this.$store.commit("drayage/setChosenLoad", []);
          } else {
            this.$store.commit("drayage/setChosenLoad", response?.data_rates);
          }
          // await this.$store.dispatch("load/getLoadById", response.loadId);
          this.changeView("CarriersInfo", response?.data_load?.load_id);
        }
      } catch (error) {
        console.error("Error creating drayage load:", error);
      } finally {
        this.loading = false;
      }
    },
    async updateDrayageLoad() {
      this.loading = true;
      this.portList = this.$store.getters["drayage/getPortList"];
      try {
        await this.validateChangeStops();
        await this.updateLoad();
        let payload = {};
        payload = {
          load_id: this.$route.params.loadId,
          terminal: this.drayageSchema?.stops[0]?.terminal_code || this.portList?.terminal_code,
          type: this.selectedOperation,
          size: this.lineItems[0]?.type_container,
          ...((this.hazmatType) && { hazmat_type: this.hazmatType })
        };
        const response = await this.updateLoadDrayage(payload);
        if (response?.data_load) {
          this.$store.commit("drayage/setChosenLoad", response?.data_rates);
          await this.$store.dispatch("load/getLoadById", response?.data_load?.load_id);
          this.changeView("CarriersInfo", response?.data_load?.load_id);
        }
      } catch (error) {
        console.error("Error update drayage load:", error);
      } finally {
        this.loading = false;
      }
    },
    changeView(routeName, loadId) {
      this.$router
        .push({
          name: routeName,
          params: {
            loadId,
            socket: true,
          },
        })
        .catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },
    async updateLoad() {
      const updatePayload = {};
      await this.$refs.AccessorialDrayage.handleUpdateRequests();
      await this.$refs.lineItemDrayage.updateLineItemsToLoad();
      if (this.prevCargoValue !== this.cargoValue) updatePayload.cargo_value_for_insurance = this.cargoValue || null;
      if (this.selectedCustomer?.id !== this.chosenLoad.customer.id) {
        updatePayload.customer_id = this.selectedCustomer?.id || process.env.VUE_APP_ACCOUNT_ID_TEST;
      }
      if (this.chosenLoad.miles !== this.milesValue) updatePayload.miles = this.milesValue;
      if (Object.keys(updatePayload).length !== 0) {
        await this.$store.dispatch("load/updateLoad", {
          id: this.$route.params.loadId,
          body: updatePayload,
        });
      }
    },
    async validateChangeStops() {
      // Check if any stop changed
      for (let i = 0; i < this.drayageSchema.stops.length; i += 1) {
        const currentStop = this.chosenLoad.stops.find(
          (stop) => stop.is_pickup === this.drayageSchema.stops[i].is_pickup
        );
        if (this.validateUpdateStops(this.drayageSchema.stops[i], currentStop)) {
          const data = {
            load_id: this.$route.params.loadId,
            stop_id: currentStop.id,
            body: this.drayageSchema.stops[i],
          };
          if (!data.body.location_id) {
            data.body.contact_info_id = null;
          }
          if (this.$options.filters.checkPermission("patch:stop")) {
            // eslint-disable-next-line no-await-in-loop
            const response = await this.$store.dispatch(
              "load/stops/updateStop",
              data
            );
            const newStop = response.stop;
            newStop.state_code = response.stop.state_province;
            newStop.zip_code = response.stop.postal_code;
          }
        }
        if (this.validateUpdateExpectedDate(this.drayageSchema.stops[i], currentStop)
        ) {
          const dataExpectedDate = {
            load_id: this.$route.params.loadId,
            stop_id: this.chosenLoad.stops[i].id,
            body: {
              expected_date: null,
            },
          };
          if (this.$options.filters.checkPermission("patch:stop")) {
            // eslint-disable-next-line no-await-in-loop
            await this.$store.dispatch("load/stops/updateStop", dataExpectedDate);
          }
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.drayage-load {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__selector {
    width: 350px;
    @media (max-width: 430px) {
      width: 100%;
    }
  }
}

.cargo-value-container {
  width: 350px;
}

.row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.miles-container {
  width: 47%;
}

.button-container {
  width: 100%;
  margin: 20px auto 0px auto;
}

.button-quote {
  border-radius: 15px;
  margin-right: 10px;
  width: 25%;
  border: none;
  background-color: $color-primary-trigger-button;
  color: $color-white;
  height: 40px;
  font-weight: bold;
  @include font-button-text;
  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin: 20px auto 0px auto;
  }
}

@media (max-width: 670px) {
  ::v-deep {
    .miles-slider-container {
      width: 100%;
    }
    .customers-searcher .form-container {
      width: 100% !important;
    }
  }
  .drayage-load__selector {
    width: 100%;
  }
  .cargo-value-container {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .row-content {
    flex-direction: column;
  }
  .miles-container {
    width: 100%;
  }
}
.missing-field ::v-deep .rc-select-container {
  background: $missing-fields;
}
</style>
