<template>
  <div class="row-accessorials">
    <div class="accessorials-spinner" v-if="loading">
      <b-spinner small></b-spinner>
    </div>
    <template v-if="!loading">
      <div class="row-accessorials__row">
        <div class="row-accessorials__information">
          <template v-for="(accessorial, index) of sortedAccessorials.pickup">
            <div
              v-if="accessorial.most_common"
              :key="index"
              class="row-accessorials__information--row"
            >
              <b-form-checkbox
                v-model="selectedCommonAccessorials[accessorial.id]"
                @change="accessorialsCheckboxAction($event, accessorial)"
                :id="accessorial.id.toString()"
              />
              <label :for="accessorial.id.toString()">{{ accessorial.name }}</label>
            </div>
          </template>
        </div>
        <div class="row-accessorials__information">
          <template v-for="(accessorial, index) of sortedAccessorials.delivery">
            <div
              v-if="accessorial.most_common"
              :key="index"
              class="row-accessorials__information--row"
            >
              <b-form-checkbox
                v-model="selectedCommonAccessorials[accessorial.id]"
                @change="accessorialsCheckboxAction($event, accessorial)"
                :id="accessorial.id.toString()"
              />
              <label :for="accessorial.id.toString()">{{ accessorial.name }}</label>
            </div>
          </template>
        </div>
      </div>
      <div class="row-accessorials__row top-line">
        <div :class="['general-accessorials-container', { 'drayage-mode': quoteMode === 'drayage' }]">
          <template v-for="(accessorial, index) of sortedAccessorials.general">
            <div
              v-if="accessorial.most_common"
              :key="index"
              class="row-accessorials__information-general"
            >
              <b-form-checkbox
                v-model="selectedCommonAccessorials[accessorial.id]"
                @change="accessorialsCheckboxAction($event, accessorial)"
                :id="accessorial.id.toString()"
              />
              <label :for="accessorial.id.toString()">{{ accessorial.name }}</label>
              <HazardousSelector
                v-if="accessorial.name.toLowerCase() === 'hazardous material handling' && selectedCommonAccessorials[accessorial.id]"
                v-model="selectedHazardousOption"
                @change="handleHazardousSelection"
              />
            </div>
          </template>
        </div>
        <div class="button-container" v-if="quoteMode !== 'drayage'">
          <Badge :quantity="getChosenAccessorials" v-if="getChosenAccessorials > 0" />
          <button
            id="more-accessorials"
            @click="openMoreAccessorialsModal"
            class="row-accessorials__button"
          >
            {{ $t("allOptions.moreAccessorials") }}
          </button>
        </div>
        <b-popover
          v-if="selectedMoreAccessorials && selectedMoreAccessorials.length > 0"
          :key="keyRenderMoreAccessorials"
          target="more-accessorials"
          placement="top"
          triggers="hover"
        >
          <template v-slot:default>
            <ul class="more-accessorials-list">
              <li
                class="more-accessorials-list__item"
                v-for="item in selectedMoreAccessorials"
                :key="item.id"
              >
                {{ item.name }}
              </li>
            </ul>
          </template>
        </b-popover>
      </div>
      <NonModeAccessorialsVue :nonModeAccessorials="nonModeAccessorials" />
      <CustomModal v-model="showHazmatModal" size="md" :centered="true" modalTitle="Hazmat">
        <template #modal-content>
          <HazmatInfoModal @closeHazmatModal="closeHazmatModal" />
        </template>
      </CustomModal>
      <b-modal
        centered
        size="lg"
        id="more-accessorials"
        ref="more-accessorials"
        hide-header
        hide-footer
        no-close-on-backdrop
        v-model="showMoreAccessorials"
      >
        <MoreAccessorials
          v-model="showMoreAccessorials"
          :departmentId="departmentId"
          :quoteMode="quoteMode"
        />
      </b-modal>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Badge from "../../../../components/Badge.vue";
import MoreAccessorials from "./MoreAccessorials.vue";
import Accessorials from "../../../../Extend/Accessorials";
import NonModeAccessorialsVue from "./NonModeAccessorials.vue";
import HazmatInfoModal from "./components/HazmatInfoModal.vue";
import CustomModal from "../../../../components/CustomModal.vue";
import HazardousSelector from "../../Drayage/HazardousSelector.vue";

export default {
  name: "accessorials",
  components: {
    MoreAccessorials,
    Badge,
    NonModeAccessorialsVue,
    HazmatInfoModal,
    CustomModal,
    HazardousSelector,
  },
  mixins: [Accessorials],
  data() {
    return {
      selectedHazardousOption: this.$store.getters["drayage/getHazmatType"],
    };
  },
  computed: {
    ...mapGetters({
      classifiedAccessorials: "load/accessorials/getClassifiedAccessorials",
    }),
  },
  methods: {
    ...mapActions("drayage", ["setHazmatType"]),
    handleHazardousSelection(option) {
      this.setHazmatType(option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.row-accessorials {
  color: $color-gray-dark;
  @include font-standart-text;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  label {
    margin-top: 3px;
    color: $color-primary-company;
  }
  &__information-general {
    display: flex;
    flex-direction: row;
    &:first-child {
      margin-right: 15px;
    }
  }
  &__information {
    display: flex;
    flex-direction: column;
    width: 50%;
    &--row {
      display: flex;
      flex-direction: row;
    }
  }
  &__row {
    display: flex;
    padding-left: 1%;
    padding-bottom: 1%;
    align-items: center;
  }
  &__button {
    @include primary-button($heightSize: 40px);
    border-radius: 15px;
    @include font-button-text;
    font-weight: 400;
  }
}

.top-line {
  border-top: 1px solid $color-border-container;
  padding-top: 1%;
  flex-direction: row;
  justify-content: center;
}

.general-accessorials-container {
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  gap: 10px;
}

.button-container {
  position: relative;
  width: 30%;
}

.more-accessorials-list {
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  &__item {
    color: $color-primary-company;
  }
}
.drayage-mode {
  width: 100%;
}
///****Responsive styles****\\\\

@media (max-width: 820px) {
  .row-accessorials {
    // display: grid;
    // grid-template-columns: repeat(3, 35%);
    // justify-content: space-between;
    // row-gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 660px) {
  .row-accessorials {
    // display: grid;
    // grid-template-columns: repeat(2, 48%);
    // row-gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 490px) {
  .row-accessorials-responsive {
    display: contents;
  }
  .row-accessorials {
    display: none;
  }
}
::v-deep {
  .modal-body {
    padding: 0;
    background-color: $color-gray-light;
    border-radius: 10px;
  }

  .modal-lg {
    @media (min-width: 992px) {
      max-width: 630px;
    }
  }

  .modal-content {
    border: 1px solid $color-border-container;
    border-radius: 10px;
  }

  .spinner-border {
    color: $color-primary-company;
  }
  .spinner-border-sm {
    width: 1.5rem;
    height: 1.5rem;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    border: 0.15em solid #325FCC;
  }
}
</style>
