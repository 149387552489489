<template>
  <div class="customers-searcher" :key="key">
    <AccountIdsSearcher
      v-model="customerValue"
      label="Customer"
      :floatLabel="true"
    />
    <button
      v-if="customerValue"
      @click="showModalInfo = true"
      class="tooltip-icon"
      type="button"
    >
      <i class="ion ion-information-circle-outline"></i>
    </button>
    <CustomModal
      v-model="showModalInfo"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      modalTitle="Customer Information"
      size="md"
    >
      <template #modal-content>
        <CustomerInformation
          @closeModal="showModalInfo = false"
          :customer="customerValue"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import AccountIdsSearcher from '../../../components/NewAccountIdsSearcher.vue';
import CustomModal from '../../../components/CustomModal.vue';
import CustomerInformation from '../LoadCreation/CustomerInformation.vue';

export default {
  name: "CustomerSearch",
  components: {
    AccountIdsSearcher,
    CustomModal,
    CustomerInformation,
  },
  props: {
    selectedCustomer: Object,
  },
  data() {
    return {
      customerValue: null,
      showModalInfo: false,
      key: 0
    };
  },
  watch: {
    selectedCustomer: {
      immediate: true,
      deep: true,
      handler(value) {
        this.customerValue = value;
        this.key += 1;
      },
    },
    customerValue: {
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.customers-searcher {
  display: flex;
  margin: 1% 0px 10px 0px;
  & ::v-deep .form-container {
    width: 350px;
    @media (max-width: 670px) {
      width: 100%;
    }
  }
  & > div {
    @media (max-width: 670px) {
      width: 100%;
    }
  }
}

.tooltip-icon {
  @include icon-input;
  font-size: 18px;
  outline: none;
}
</style>
