import { mapGetters } from "vuex";
import modes from "../views/QuoteBook/modes";
import lineItemsDimensionsSchema from "../schemas/lineItemsDimensionsSchema";

export default {
  /* eslint-disable max-len */
  name: "Accessorials",
  props: {
    loadDetailsAccessorials: {
      type: Array,
      required: false,
    },
    departmentId: {
      type: Number,
    },
    quoteMode: String,
  },
  data() {
    return {
      nonModeAccessorials: [],
      showMoreAccessorials: false,
      loading: true,
      showHazmatModal: false,
      key: 0,
      lineItemsDimensions: null,
      selectedMoreAccessorials: null,
      keyRenderMoreAccessorials: 0
    };
  },
  computed: {
    getChosenAccessorials() {
      const chosenAccessorials = [
        ...this.$store.getters["load/accessorials/getChosenAccessorials"],
      ];
      this.selectedMoreAccessorials = chosenAccessorials.filter((item) => !item.most_common);
      this.keyRenderMoreAccessorials += 1;
      return this.selectedMoreAccessorials.length;
    },
    selectedCommonAccessorials: {
      get() {
        return this.$store.getters["load/accessorials/getSelectedCommonAccessorials"];
      },
    },
    defaultCheckedAccessorials: {
      get() {
        return this.$store.getters["load/accessorials/getDefaultCheckedAccessorials"];
      },
    },
    accessorials: {
      get() {
        return this.$store.getters["load/accessorials/getAccessorials"](this.departmentId);
      },
    },
    // Stackable linear feet - function
    ...mapGetters({
      vuexLineItems: "load/lineItems/getSelectedLineItems",
    }),
    sortedAccessorials() {
      const departmentData = this.classifiedAccessorials[this.departmentId] || {};
      const sorted = { ...departmentData };
      const sortFunction = (a, b) => {
        if (a.name.toLowerCase() === 'hazardous material handling') return 1;
        if (b.name.toLowerCase() === 'hazardous material handling') return -1;
        return 0;
      };
      ['pickup', 'delivery', 'general'].forEach((key) => {
        sorted[key] = Array.isArray(sorted[key]) ? sorted[key].sort(sortFunction) : [];
      });
      return sorted;
    },
  },
  watch: {
    defaultCheckedAccessorials: {
      deep: true,
      handler(newValue, oldValue) {
        const selectedAccessorials = [];
        newValue.accessorials.forEach((defaultCheckedItem) => {
          const accessorial = this.accessorials.find((item) => item.id === defaultCheckedItem);
          if (accessorial) {
            selectedAccessorials.push(accessorial);
          }
        });
        if (newValue.id !== oldValue.id && newValue.type === oldValue.type) {
          const accessorialToDelete = [];
          oldValue.accessorials.forEach((defaultCheckedItem) => {
            const accessorial = this.accessorials.find((item) => item.id === defaultCheckedItem);
            if (accessorial) {
              accessorialToDelete.push(accessorial);
            }
          });
          this.deleteAccessorialRelatedToLocation(accessorialToDelete, oldValue.type);
        }
        this.checkedDefaultAccessorials(selectedAccessorials, this.defaultCheckedAccessorials.type);
        this.notFoundAccessorials(this.defaultCheckedAccessorials);
        this.key += 1;
      },
    },
    vuexLineItems: {
      deep: true,
      handler(val) {
        const lineItemsDimensions = val.map((item) => ({
          handling_unit_count: parseInt(item.handling_unit_count, 10),
          weight: parseInt(item.weight, 10),
          width: parseInt(item.width, 10),
          height: parseInt(item.height, 10),
          length: parseInt(item.length, 10),
        }));
        this.buildLineItemsSchema(lineItemsDimensions);
      },
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("load/accessorials/getAllAccessorials", this.departmentId);
    this.allAccessorials = await this.$store.dispatch("load/accessorials/getAllAccessorials", -1);
    if (this.$route.params.loadId) {
      this.assignRevenovaId(this.loadDetailsAccessorials);
      this.markCheckboxes(this.loadDetailsAccessorials);
      this.setLoadChosenAccessorials(this.loadDetailsAccessorials);
    } else {
      this.handleStackbleAccessorial();
    }
    this.$store.commit("load/accessorials/classifyAccessorials", this.departmentId);
    this.loading = false;
  },
  methods: {
    // Stackable linear feet - function
    buildLineItemsSchema(items) {
      /* eslint-disable */
      this.lineItemsDimensions = items.filter((element) =>
        lineItemsDimensionsSchema.isValidSync(element)
      );
    },
    async calculateLineItemsInfo(value) {
      if (!this.lineItemsDimensions.length) {
        return;
      }
      const modeId = modes[this.$route.params.quoteType].modeId;
      const payload = {
        body: {
          mode: modeId,
          stackable: value,
          line_items: this.lineItemsDimensions,
        },
        params: {
          include_lf: true,
        },
      };
      const response = await this.$store.dispatch("standardizers/calculateLineItemsInfo", payload);
      this.$store.commit("load/lineItems/setLineItemsResumeInfo", response);
    },
    // Accessorial
    capitalizeWord(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    handleStackbleAccessorial(itsRemove) {
      const stackable = this.allAccessorials.find((item) => item.name.toLowerCase() === "stackable");
      if (itsRemove) {
        this.deleteAccessorial(stackable);
        this.$store.commit("load/accessorials/setSelectedCommonAccessorialStackable", { body: stackable, value: false });
        return;
      }
      if(this.departmentId !== 6){
        this.addAccessorial(stackable);
        this.$store.commit("load/accessorials/setSelectedCommonAccessorialStackable", { body: stackable, value: true });
      }
    },
    handleNonStackbleAccessorial(itsRemove) {
      const nonStackable = this.allAccessorials.find((item) => item.name.toLowerCase() === "non stackable");
      if (itsRemove) {
        this.deleteAccessorial(nonStackable);
        this.$store.commit("load/accessorials/setSelectedCommonAccessorialStackable", { body: nonStackable, value: false });
        return;
      }
      this.addAccessorial(nonStackable);
      this.$store.commit("load/accessorials/setSelectedCommonAccessorialStackable", { body: nonStackable, value: true });
    },
    behaviorOfHamat(value, accessorial) {
      if (accessorial.name.toLowerCase() === "hazmat" && value) {
        this.showHazmatModal = true;
      }
    },

  behaviorOfHazardous(value, accessorial) {
    if (accessorial.name.toLowerCase() === "hazardous material handling" && value) {
      this.selectedHazardousOption = null;
    }
  },
  handleHazardousSelection(option) {
    this.selectedHazardousOption = option;
  },
    async behaviorOfStackable(value, accessorial) {
      if (accessorial.name.toLowerCase() === "stackable") {
        if (!value) {
          await this.calculateLineItemsInfo(false);
          this.handleNonStackbleAccessorial();
        }
        if (value) {
          await this.calculateLineItemsInfo(true);
          this.handleNonStackbleAccessorial(true);
        }
      }
    },
    async behaviorOfNonStackable(value, accessorial) {
      if (accessorial.name.toLowerCase() === "non stackable") {
        if (!value) {
          await this.calculateLineItemsInfo(true);
          this.handleStackbleAccessorial();
        }
        if (value) {
          await this.calculateLineItemsInfo(false);
          this.handleStackbleAccessorial(true);
        }
      }
    },
    async accessorialsCheckboxAction(value, accessorial) {
      if(accessorial.name.toLowerCase() === 'hazardous material handling' && this.quoteMode === 'drayage'  && !value ){
        this.$store.commit("drayage/setHazmatType", null);
      }
      if (value) {
        this.$store.commit("load/setUpdatingRequestInProcess", true);
        await this.addAccessorial(accessorial);
        this.$store.commit("load/setUpdatingRequestInProcess", false);
      } else {
        this.$store.commit("load/setUpdatingRequestInProcess", true);
        await this.deleteAccessorial(accessorial);
        this.$store.commit("load/setUpdatingRequestInProcess", false);
      }
      this.behaviorOfHamat(value, accessorial);
      this.behaviorOfHazardous(value, accessorial);
      await this.behaviorOfStackable(value, accessorial);
      await this.behaviorOfNonStackable(value, accessorial);
    },
    closeHazmatModal() {
      this.showHazmatModal = false;
    },
    closeHazardousModal() {
      this.showHazardousModal = false;
    },
    getUpdateAccessorialsRequest() {
      const requests = [];
      const chosenAccessorials = this.$store.getters["load/accessorials/getChosenAccessorials"];
      const deletedAccessorials = this.loadDetailsAccessorials.filter(
        (item) => !chosenAccessorials.find((accessorial) => accessorial.id === item.accessorial_id)
      );
      const createdAccessorials = chosenAccessorials.filter(
        (item) =>
          !this.loadDetailsAccessorials.find(
            (accessorial) => accessorial.accessorial_id === item.id
          )
      );
      createdAccessorials.forEach((accessorial) => {
        requests.push(this.postAccessorialRequest(accessorial));
      });
      deletedAccessorials.forEach((accessorial) => {
        requests.push(this.deleteAccessorialRequest(accessorial));
      });
      return requests;
    },
    assignRevenovaId(accessorials) {
      this.$store.commit("load/accessorials/assignRevenovaId", {
        accessorials,
        departmentId: this.departmentId,
      });
    },
    setLoadChosenAccessorials(loadAccessorials) {
      const allAccessorials = this.$store.getters["load/accessorials/getAccessorials"](
        this.departmentId
      );
      const filteredLoadAccessorialsByMode = loadAccessorials.filter((accessorial) =>
        allAccessorials.find((item) => (accessorial.tms_code.accessorial_id) === item.saleforce_id)
      );
      this.nonModeAccessorials = this.findNonModeAccessorials(allAccessorials, loadAccessorials);
      /* eslint-disable */
      const chosenAccessorials = filteredLoadAccessorialsByMode.map((item) => {
        const foundAccessorial = allAccessorials.find(
          (accessorial) => accessorial.id === item.accessorial_id
        );
        return foundAccessorial;
      });
      this.$store.commit("load/accessorials/setLoadChosenAccessorials", chosenAccessorials);
    },
    findNonModeAccessorials(allAccessorials, loadAccessorials) {
      const nodModeAccessorials = loadAccessorials.filter(
        (accessorial) =>
          !allAccessorials.find((item) => accessorial.tms_code.accessorial_id === item.saleforce_id)
      );
      return nodModeAccessorials;
    },
    checkedDefaultAccessorials(accessorials, type) {
      const chosenAccessorials = this.$store.getters["load/accessorials/getChosenAccessorials"];
      const filteredAccessorials = accessorials.filter((item) => {
        if (
          (type === "pickup" &&
            (item.accessorial_type === "pick up" || item.accessorial_type === "general")) ||
          (type === "delivery" &&
            (item.accessorial_type === "delivery" || item.accessorial_type === "general"))
        ) {
          if (!chosenAccessorials.some((chosenItem) => chosenItem.name === item.name)) {
            item.checked = true;
            return true;
          }
          return false;
        }
        return false;
      });
      if (filteredAccessorials.length > 0) {
        filteredAccessorials.forEach((item) => {
          this.addAccessorial(item);
        });
        this.markCheckboxes(filteredAccessorials, true);
      }
    },
    deleteAccessorialRelatedToLocation(accessorials, type) {
      const chosenAccessorials = this.$store.getters["load/accessorials/getChosenAccessorials"];
      const filteredAccessorials = accessorials.filter((item) => {
        if (
          (type === "pickup" &&
            (item.accessorial_type === "pick up" || item.accessorial_type === "general")) ||
          (type === "delivery" &&
            (item.accessorial_type === "delivery" || item.accessorial_type === "general"))
        ) {
          if (
            item.checked &&
            chosenAccessorials.some((chosenItem) => chosenItem.name === item.name)
          ) {
            item.checked = false;
            return true;
          }
          return false;
        }
        return false;
      });
      if (filteredAccessorials.length > 0) {
        filteredAccessorials.forEach((item) => {
          this.deleteAccessorial(item);
        });
        this.uncheckCheckboxes(filteredAccessorials, true);
      }
    },
    markCheckboxes(accessorials, locationAccessorial) {
      if (locationAccessorial) {
        accessorials.forEach((accessorial) => {
          this.selectedCommonAccessorials[accessorial.id] = true;
        });
        return;
      }
      accessorials.forEach((accessorial) => {
        this.selectedCommonAccessorials[accessorial.accessorial_id] = true;
      });
    },
    uncheckCheckboxes(accessorials, locationAccessorial) {
      if (locationAccessorial) {
        accessorials.forEach((accessorial) => {
          this.selectedCommonAccessorials[accessorial.id] = false;
        });
        return;
      }
      accessorials.forEach((accessorial) => {
        this.selectedCommonAccessorials[accessorial.accessorial_id] = false;
      });
    },
    async addAccessorial(accessorial) {
      this.$store.commit("load/accessorials/setChosenAccessorials", { ...accessorial });
      // if (this.$route.params.loadId) {
      //   await this.postAccessorialRequest(accessorial);
      // }
    },
    async deleteAccessorial(accessorial) {
      this.$store.commit("load/accessorials/deleteFromChosenAccessorials", { ...accessorial });
      // if (this.$route.params.loadId) {
      //   await this.deleteAccessorialRequest(accessorial);
      // }
    },
    async postAccessorialRequest(accessorial) {
      if (this.$options.filters.checkPermission("post:load-accessorial")) {
        const { loadId } = this.$route.params;
        const body = {
          id_load: loadId,
          accessorial_id: accessorial.id,
          ...(accessorial.accessorial_type !== "general" && {
            accessorial_type: accessorial.accessorial_type.replace(" ", ""),
          }),
        };
        return this.$store.dispatch("load/accessorials/createAccessorialsByLoad", {
          id: loadId,
          body,
          departmentId: this.departmentId,
        });
      }
      return null;
    },
    deleteAccessorialRequest(accessorial) {
      if (this.$options.filters.checkPermission("delete:load-accessorial")) {
        const body = {
          load_id: this.$route.params.loadId,
          // accessorial_id: accessorial.revenova_id,
          accessorial_id: accessorial.id,
        };
        return this.$store.dispatch("load/accessorials/deleteAccessorialsByLoad", body);
      }
      return null;
    },
    openMoreAccessorialsModal() {
      this.showMoreAccessorials = true;
    },
    notFoundAccessorials(locationAccessorial) {
      const notFoundAccessorial = locationAccessorial.accessorials.filter(
        (id) => !this.accessorials.some((accessorial) => accessorial.id === id)
      );
      if (notFoundAccessorial.length > 0) {
        const notFoundNames = notFoundAccessorial.map((id) => this.getAccessorialName(id));
        const listText = notFoundNames.map((name) => `- ${name}`).join("\n");
        this.swal({
          title:
            "The location selected has the  following accessorials as default, that are not supported in this Mode:",
          icon: "warning",
          text: listText,
          dangerMode: true,
          buttons: {
            cancel: "Cancel",
          },
        });
      }
    },
    getAccessorialName(accessorialId) {
      const idValue = parseInt(accessorialId, 10);
      const matchedAccessorial = this.allAccessorials.find(
        (accessorial) => accessorial.id === idValue
      );
      return matchedAccessorial ? matchedAccessorial.name : "";
    },
  },
};
