<template>
  <div class="accessorials-drayage">
    <div class="row-accessorials" v-if="screenWidth > 490">
      <Accessorials
        :departmentId="departmentId"
        :ref="accessorialsRef"
        :loadDetailsAccessorials="selectedAccessorials"
        :quoteMode="quoteMode"
        :key="renderKey"
      />
    </div>
    <div class="accessorials-responsive" v-else>
      <AccessorialsResponsive
        :departmentId="departmentId"
        :ref="accessorialsRef"
        :loadDetailsAccessorials="selectedAccessorials"
        :quoteMode="quoteMode"
        :key="renderKey"
      />
    </div>
    <hr />
  </div>
</template>

<script>
import Accessorials from "../LoadCreation/accessorials/Accessorials.vue";
import AccessorialsResponsive from "../LoadCreation/accessorials/AccessorialsResponsive.vue";
import onResize from "../../../Extend/onResize";

export default {
  name: "AccessorialsDrayage",
  components: {
    Accessorials,
    AccessorialsResponsive,
  },
  props: {
    departmentId: Number,
    quoteMode: String,
    selectedAccessorialsInfo: Array,
  },
  mixins: [onResize],
  data() {
    return {
      accessorialsRef: "accessorials",
      selectedAccessorials: [],
      renderKey: 0
    };
  },
  watch: {
    screenWidth() {
      if (this.screenWidth <= 490) {
        this.accessorialsRef = "accessorialsResponsive";
      } else {
        this.accessorialsRef = "accessorials";
      }
    },
    selectedAccessorialsInfo(value) {
      this.selectedAccessorials = value;
      this.renderKey = +1;
    }
  },
  methods: {
    async handleUpdateRequests() {
      const accessorialsRequests = this.$refs[
        this.accessorialsRef
      ].getUpdateAccessorialsRequest();
      const responseAccessorials = await Promise.all(accessorialsRequests).then((response) => response);
      return responseAccessorials.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Checkboxes.scss";

.accessorials-drayage {
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 120px;
    gap: 8px;
  }
  &-list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

label {
  margin: 0px;
}

hr {
  border-top: 1px solid $color-border-container;
}

::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  border: 0.15em solid #325fcc;
}
</style>
