<template>
  <div class="miles-slider-container">
    <div class="miles-slider__content">
      <el-slider v-model="miles" :min="min" :max="max" @change="handleSlider"></el-slider>
      <ul class="miles-inputs">
        <li class="miles__input">
          <input
            :class="error ? 'missing-field' : ''"
            class="miles__input miles__input-number"
            type="number"
            :min="min"
            :max="max"
            v-model="miles"
            @input="changeMileValue"
          />
        </li>
        <li class="miles__input miles__input-measurement">
          <input
            class="miles__input miles__input-info"
            type="text"
            placeholder="mi"
            disabled
          />
        </li>
      </ul>
    </div>
    <em class="info-text">Terminals with a range of</em>
  </div>
</template>

<script>
export default {
  name: "MilesSlider",
  data() {
    return {
      miles: 100,
      min: 0,
      max: 400,
      error: false,
      debounceTimer: null,
      debounceDelay: 700,
    };
  },
  props: {
    milesValue: Number,
  },
  watch: {
    milesValue: {
      deep: true,
      handler(value) {
        if (value) {
          this.miles = this.milesValue;
        }
      }
    },
  },
  methods: {
    changeMileValue(value) {
      clearTimeout(this.debounceTimer);
      const parseMile = parseInt(value.target.value, 10);
      this.miles = parseMile;

      this.debounceTimer = setTimeout(() => {
        this.$emit('input', this.miles);
      }, this.debounceDelay);
    },
    beforeDestroy() {
      clearTimeout(this.debounceTimer);
    },
    handleSlider(value) {
      this.miles = value;
      this.$emit('input', this.miles);
    },
    validatePositiveNumber() {
      this.error = !this.miles;
      return !this.error;
    }
  },
};
</script>

<style lang="scss" scoped>
.miles {
  &-slider-container {
    text-align: start;
    width: 100%;
  }
  &-slider__content {
    display: flex;
    gap: 15px;
  }
  &-inputs {
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 40px;
    & li {
      &:nth-child(1) {
        width: 80px;
        border: 1px solid $color-border-container;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:nth-child(2) {
        width: 50px;
        border: 1px solid $color-border-container;
        border-left: none;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  &__input {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    text-align: center;
    &-number {
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }
    &-measurement {
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
    }
  }
}

.info-text {
  font-size: 13px;
}

.missing-field {
  background: $missing-fields;
}

::v-deep {
  .el-slider {
    width: 100%;
  }
  .el-slider__runway {
    height: 4.5px;
  }
  .el-slider__bar {
    background: $color-primary-company;
    height: 4.5px;
  }
  .el-slider__button {
    background: $color-border-container;
    border: none;
  }
}
</style>
