<template>
  <div class="line-items-drayage">
    <section
      class="lineitem-container"
      :id="'line-item' + index"
      v-for="(option, index) in selectedLineItems"
      :key="index"
    >
      <div class="lineitem-row">
        <div class="type-container lineitem-inputs">
          <RCSelect
            class="type-container__input"
            :class="{
              'missing-field': errors['type_container'],
              'disabled': disabled
            }"
            label="Type Container"
            v-model="option.type_container"
            :floatLabel="true"
            :requiredField="true"
            :suggestions="options"
            :key="renderKey"
          />
        </div>
        <ul class="weight lineitem-inputs">
          <li class="weight__input">
            <RCInput
              :class="{
                'missing-field': errors['weight'],
                'disabled': disabled
              }"
              v-model="option.weight"
              :label="$t('allOptions.weight')"
              :floatLabel="true"
              :positiveNumbers="true"
              :requiredField="true"
              type="number"
            />
          </li>
          <li class="weight__dropdown">
            <RCSelect
              :class="{
                'missing-field': errors['weight_units'],
                'disabled': disabled
              }"
              v-model="option.weight_units"
              :suggestions="weight_units"
              :label="$t('allOptions.weightUnits')"
            />
          </li>
        </ul>
        <div class="description lineitem-inputs">
          <RCInput
          :class="{
              'missing-field': errors['description'],
              'disabled': disabled
            }"
            v-model="option.description"
            :label="$t('allOptions.commodity')"
            :floatLabel="true"
            :type="'text'"
            :requiredField="true"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LineItemDrayageSchema from "../../../schemas/LineItemsDrayageSchema";
import RCSelect from "../../../components/RCComponents/CustomRCSelect.vue";
import RCInput from "../../../components/RCComponents/CustomRCInput.vue";

export default {
  name: "LineItemsDrayage",
  components: {
    RCSelect,
    RCInput,
  },
  props: {
    lineItems: Array,
    disabled: Boolean,
    selectedCarrier: Object,
  },
  data() {
    return {
      selectedLineItems: [
        {
          type_container: null,
          weight_units: "lbs",
          weight: null,
          description: null,
        },
      ],
      errors: {
        type_container: false,
        weight_units: false,
        weight: false,
        description: false,
      },
      options: [
        { label: "20 ft", value: "20" },
        { label: "40 ft", value: "40" },
        { label: "45 ft", value: "45" },
      ],
      weight_units: [
        { label: "lbs", value: "lbs" },
        { label: "kgs", value: "kgs" },
        { label: "ton", value: "ton" },
      ],
      renderKey: 0,
    };
  },
  watch: {
    selectedLineItems: {
      handler() {
        this.$emit("input", this.selectedLineItems);
      },
      deep: true,
      immediate: true,
    },
    lineItems: {
      handler(value) {
        this.assignLineItems(value);
        this.renderKey += 1;
      },
      deep: true,
      immediate: true,
    }
  },
  created() {
    this.assignLineItems(this.lineItems);
  },
  methods: {
    updateLoadLineItems() {
      return [Promise.resolve()];
    },
    async validateFormfields() {
      let validateForm;
      this.errors = {
        type_container: false,
        weight_units: false,
        weight: false,
        description: false,
      };
      try {
        await LineItemDrayageSchema.validate(this.selectedLineItems[0], {
          abortEarly: false,
        });
        validateForm = true;
      } catch (error) {
        validateForm = false;
        error.inner.forEach((element) => {
          this.errors[element.path] = true;
        });
      }
      return validateForm;
    },
    assignLineItems(lineItems) {
      if (lineItems && lineItems.length > 0) {
        this.selectedLineItems = lineItems.map((item) => ({
          ...item,
          type_container: this.selectedCarrier.size
        }));
      }
    },
    updateLineItemsToLoad() {
      const lineItemsRequests = [];
      const chosenLoad = this.$store.getters["load/getChosenLoad"];
      this.selectedLineItems.forEach((item, index) => {
        const typeContainer = item.type_container;
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: item.line_item_id,
          body: item,
        };
        this.removeUnusedProperties(item);
        const itemValidation = this.validateItemsForUpdates(
          chosenLoad.line_items,
          this.selectedLineItems[index]
        );
        if (itemValidation) {
          if (this.$options.filters.checkPermission("patch:line-item")) {
            lineItemsRequests.push(
              this.$store.dispatch("load/lineItems/updatedLineItem", payload)
            );
          }
        }
        this.selectedLineItems[index].type_container = typeContainer;
      });
      return lineItemsRequests;
    },
    removeUnusedProperties(item) {
      delete item.density;
      delete item.linear_feet;
      delete item.nmfc_number;
      delete item.type_container;
    },
    /*
      validate if the chosen load and selected line items
      have changes and validate if is necessary dispatch the function to update.
    */
    validateItemsForUpdates(chosenLoadLineItems, item) {
      const foundItem = chosenLoadLineItems.find(
        (lineItem) => lineItem.line_item_id === item.line_item_id
      );
      let itemWasModified = false;
      if (foundItem) {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          // eslint-disable-next-line eqeqeq
          if (item[key] != foundItem[key]) {
            itemWasModified = true;
          }
          return null;
        });
      }
      return itemWasModified;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/RCComponents.scss";
.lineitem-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  @media (max-width: 650px) {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.type-container {
  flex-basis: 23%;
  @media (max-width: 860px) {
    flex-basis: 30%;
  }
  @media (max-width: 650px) {
    flex-basis: 100%;
  }
}

::v-deep .weight {
  @include remove-custom-component-styles;
  display: flex;
  flex-basis: 23%;
  list-style-type: none;
  border-radius: 10px;
  @media (max-width: 860px) {
    flex-basis: 30%;
  }
  @media (max-width: 650px) {
    flex-basis: 100%;
  }
  &__input {
    @include hide-input-number-arrows;
    width: 70%;
    & .rc-input-container {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  &__dropdown {
    width: 30%;
    & .rc-select-container {
      border-left: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.description {
  flex-basis: 52%;
  @media (max-width: 860px) {
    flex-basis: 40%;
  }
  @media (max-width: 650px) {
    flex-basis: 100%;
  }
}

.lineitem-inputs {
  margin: 0px 5px;
  height: 42px;
  & li {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
