<template>
  <div class="create-load">
    <b-overlay :show="loadingLoad" rounded="sm" class="create-load-overlay">
      <b-overlay :show="creatingLoad" rounded="sm" class="create-load-overlay">
        <div class="create-load-content">
          <div class="customers-searcher" v-if="enableCustomersSearcher">
            <CustomerSearch v-model="selectedCustomer" :selectedCustomer="selectedCustomer" />
          </div>
          <template v-if="!isBrandedApp && $route.path.includes('all-options')">
            <div class="create-load-content__title">
              <span>{{ $t("bookALoad.message") }}</span>
            </div>
            <hr />
          </template>
          <div class="create-load-content__information">
            <div class="row-stops">
              <div class="lineitems-spinner" v-if="loadingLoad">
                <b-spinner small></b-spinner>
              </div>
              <Stops
                v-else
                :stops="stopsOptions"
                :chosenLoad="chosenLoad"
                :salesforceAccounts="stopsSalesforceAccounts"
                ref="stops"
              />
            </div>
            <div class="lineitems-spinner" v-if="loadingLoad">
              <b-spinner small></b-spinner>
            </div>
            <template v-else>
              <div class="row-accessorials" v-if="screenWidth > 490">
                <Accessorials
                  :departmentId="modeInfo.departmentId"
                  :loadDetailsAccessorials="loadDetailsAccessorials"
                  :ref="accessorialsRef"
                  :quoteMode="modeInfo.id"
                />
              </div>
              <div class="accessorials-responsive" v-if="screenWidth <= 490">
                <AccessorialsResposive
                  :departmentId="modeInfo.departmentId"
                  :loadDetailsAccessorials="loadDetailsAccessorials"
                  :ref="accessorialsRef"
                  :quoteMode="modeInfo.id"
                />
              </div>
              <CargoValue
                :cargoValueLoad="chosenLoad?.cargo_value_for_insurance || 0"
                :invalidCargoValue="invalidCargoValue"
                v-model="cargoValue"
                class="cargo-value"
              />
              <div class="lineitems-container">
                <LineItems
                  :showFeatureSearchItemAndAddMoreItems="true"
                  :lineItems="selectedLineItems"
                  :quoteMode="modeInfo.id"
                  ref="lineItems"
                  v-if="modeInfo.id !== 'ftl' && modeInfo.id !== 'reefer'"
                />
                <LineItemsFTL
                  :lineItems="selectedLineItems"
                  :quoteMode="modeInfo.id"
                  ref="lineItems"
                  v-if="modeInfo.id === 'ftl' || modeInfo.id === 'reefer'"
                />
                <LineItemsResume :quoteMode="modeInfo.id" :isTenderView="false" />
                <AlertValidationMessage
                  :lineItemsMessageError="lineItemsMessageError"
                  :quoteMode="modeInfo.id"
                  v-if="lineItemsMessageError.length > 0"
                  class="mb-4"
                />
                <div
                  class="button-container"
                  v-if="$options.filters.checkPermission('post:load')"
                >
                  <b-overlay :show="updatingRequestInProcess" rounded="sm">
                    <button
                      v-if="$route.params.loadId"
                      v-shortkey.once="['ctrl', 'enter']"
                      @shortkey="createLoad(true)"
                      @click="createLoad(true)"
                      class="button-quote"
                    >
                      {{ $t("bookALoad.quoteSave") }}
                    </button>
                    <button
                      v-else-if="!$route.params.loadId"
                      v-shortkey.once="['ctrl', 'enter']"
                      @shortkey="createLoad(false)"
                      @click="createLoad(false)"
                      class="button-quote"
                    >
                      {{ $t("bookALoad.quote") }}
                    </button>
                  </b-overlay>
                </div>
              </div>
            </template>
            <div class="row-map">
              <Map />
            </div>
          </div>
        </div>
        <template v-if="creatingLoad" #overlay>
          <div class="create-load-icon">
            <b-icon icon="truck" font-scale="3" animation="cylon"></b-icon>
            <FadeAnimationTextArray :textArray="loadingTextArray" />
          </div>
        </template>
      </b-overlay>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onReSize from "@/Extend/onResize";
import ValidationUpdateStops from "@/Extend/ValidationUpdateStops";
import AlertValidationMessage from "@/components/AlertValidationMessage.vue";
import ValidationUpdateExpectedDate from "@/Extend/ValidationUpdateExpectedDate";
import CustomerSearch from "../components/CustomerSearch.vue";
import LoadStatusType from "../../../Extend/LoadStatusType";
import Accessorials from "./accessorials/Accessorials.vue";
import Stops from "./stops/Index.vue";
import LineItems from "./lineItems/LineItems.vue";
import Map from "./map/Map.vue";
import AccessorialsResposive from "./accessorials/AccessorialsResponsive.vue";
import CargoValue from "../CarrierSelection/loadResume/CargoValue.vue";
import FadeAnimationTextArray from "../../../components/FadeAnimationTextArray.vue";
import LoadingTextArray from "../../../utils/LoadingTextArray";
import LineItemsFTL from "./lineItems/LineItemsFTL.vue";
import LineItemsResume from "../../../components/LineItemsResume.vue";
import db from "../../../utils/db";

export default {
  /* eslint-disable operator-linebreak */
  /* eslint-disable func-names */
  /* eslint-disable no-await-in-loop */
  name: "CreateLoad",
  components: {
    CustomerSearch,
    Stops,
    Accessorials,
    AccessorialsResposive,
    LineItems,
    AlertValidationMessage,
    Map,
    CargoValue,
    FadeAnimationTextArray,
    LineItemsFTL,
    LineItemsResume,
  },
  mixins: [onReSize, ValidationUpdateStops, ValidationUpdateExpectedDate],
  props: {
    modeInfo: Object,
  },
  data() {
    return {
      accountInfo: null,
      screenWidth: null,
      selectedLineItems: null,
      stopsOptions: [],
      chosenLoad: null,
      loadingLoad: false,
      editStopsData: null,
      loadDetailsAccessorials: null,
      creatingLoad: false,
      stopsSalesforceAccounts: null,
      updatedChosenStops: [],
      cargoValue: null,
      prevCargoValue: null,
      invalidCargoValue: false,
      loadZipCode1: null,
      loadZipCode2: null,
      requote: false,
      selectedCustomer: null,
      hazmatRequireLineItem: null,
      loadingTextArray: LoadingTextArray.quoteBook,
      accessorialsRef: "accessorials",
      openCustomerInfoModal: false,
      limitsValidationLineItems: false,
      lineItemsMessageError: [],
    };
  },
  computed: {
    ...mapGetters({
      isHazmat: "load/accessorials/getIsHazmatLoad",
      updatingRequestInProcess: "load/getUpdatingRequestInProcess",
    }),
    isBrandedApp() {
      return process.env.VUE_APP_BRANDED_APP === "true";
    },
    enableCustomersSearcher() {
      return this.accountInfo.role.name.toLowerCase() === "glt admin";
    },
  },
  watch: {
    creatingLoad() {
      if (this.creatingLoad) {
        window.onbeforeunload = function () {
          return "handle your events or msgs here";
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    screenWidth() {
      if (this.screenWidth <= 490) {
        this.accessorialsRef = "accessorialsResponsive";
      } else {
        this.accessorialsRef = "accessorials";
      }
    },
  },
  async created() {
    this.loadingLoad = true;
    this.accountInfo = this.$store.getters["user/getMyInfo"];
    const { loadId } = this.$route.params;
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    if (loadId) {
      if (!this.chosenLoad || this.chosenLoad.id !== loadId) {
        this.chosenLoad = await this.getLoadById(loadId);
      }
      const selectedCustomer = await this.getCustomerInformationFromIndexedDB(loadId);
      this.selectedCustomer = selectedCustomer || { ...this.chosenLoad.customer };
      // Check if pickup stop has zipcode
      this.checkLoadZipcode("loadZipCode1");
      this.checkLoadZipcode("loadZipCode2");
      // Save zipcodes in vuex for the map markers
      const zipCodePayload1 = {
        type: "pickup",
        zipCode: this.loadZipCode1,
      };
      this.$store.commit("load/stops/setZipCode", zipCodePayload1);
      const zipCodePayload2 = {
        type: "delivery",
        zipCode: this.loadZipCode2,
      };
      this.$store.commit("load/stops/setZipCode", zipCodePayload2);
    }
    this.handleLoadInformation(this.chosenLoad);
    const dataSalesforceAccounts = {
      params: {
        // ONLY FOR TEST ACCOUNT
        account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
        skip: 0,
        limit: 1000,
        // account_id: this.$store.getters["login/getTokenInfo"].accounts[0],
      },
    };
    if (this.$options.filters.checkPermission("get:salesforce-accounts")) {
      this.stopsSalesforceAccounts = await this.$store.dispatch(
        "load/stops/getSalesforceAccounts",
        dataSalesforceAccounts
      );
    }
    this.loadingLoad = false;
  },
  methods: {
    async getLoadById(loadId) {
      if (this.$options.filters.checkPermission("get:load")) {
        return this.$store.dispatch("load/getLoadById", loadId);
      }
      return null;
    },
    identifyLoadStatus(load) {
      const loadStatus = LoadStatusType.methods.setLoadStatusColor(load.load_status);
      const statusIsQuoted = loadStatus.label.toLowerCase() === "quoted";
      return statusIsQuoted;
    },
    handleLoadInformation(chosenLoad) {
      // Aqui se instancia el esquema de los lineItems, tanto para la edicion como para la creacion
      // dependiendo del modo de dispatch (agregar el mode como bandera para la validacion de los limites dependiendo del mode)
      if (!chosenLoad) {
        if (this.modeInfo.id === "ftl" || this.modeInfo.id === "reefer") {
          this.selectedLineItems = [
            {
              name: "Item 1",
              handling_unit_count: "1",
              handling_units: "Truckloads",
              dimension_units: "in",
              packaging_unit_count: null,
              packaging_units: null,
              weight_units: "lbs",
              nmfc_class: 50,
              height: 1,
              length: 1,
              width: 1,
              weight: "",
              // New fields:
              user_height: 1,
              user_length: 1,
              user_width: 1,
              user_weight: "",
              user_dimension_units: "in",
              user_weight_units: "lbs",
              pickup_stop: "pickup",
              delivery_stop: "delivery",
              linear_feet: 0.1,
              description: "",
              hazardous_materials: false,
              quoteMode: this.modeInfo.id,
              lineItemName: "",
            },
          ];
          return;
        }
        this.selectedLineItems = [
          {
            name: "Item 1",
            handling_units: "Pallets",
            dimension_units: "in",
            weight_units: "lbs",
            // nmfc_number: null,
            nmfc_class: null,
            // packaging_units: null,
            height: "",
            length: "",
            width: "",
            weight: "",
            // New fields:
            user_height: "",
            user_length: "",
            user_width: "",
            user_weight: "",
            user_dimension_units: "in",
            user_weight_units: "lbs",
            pickup_stop: "pickup",
            delivery_stop: "delivery",
            linear_feet: 0.1,
            description: "",
            handling_unit_count: "",
            hazardous_materials: false,
            quoteMode: this.modeInfo.id,
            lineItemName: "",
          },
        ];
      } else {
        this.loadDetailsAccessorials = chosenLoad.accessorials;
        this.selectedLineItems = chosenLoad.line_items;
        this.selectedLineItems.forEach((element) => {
          element.quoteMode = this.modeInfo.id;
          element.lineItemName = element.description;
        });
        this.cargoValue = chosenLoad.cargo_value_for_insurance;
        this.prevCargoValue = this.cargoValue;
      }
    },
    async createLoad(requote) {
      if (this.$options.filters.checkPermission("post:load")) {
        // line items ftl limits validation
        let ftlLimitsValidation = true;
        if (this.modeInfo.id === "ftl" || this.modeInfo.id === "reefer") {
          ftlLimitsValidation = await this.$refs.lineItems.validateFTLLimits();
        }
        const stopsValidation = this.$refs.stops.validateFormfields();
        const lineItemsValidation = await this.$refs.lineItems.validateFormfields();
        const lineItemsLimitsValidation = this.$store.getters[
          "load/lineItems/getLineItemsLimitValidation"
        ];
        const validateCargoValue = this.validateCargoValue();
        const stops = this.$store.getters["load/stops/getStopsList"];
        const accessorials = this.$store.getters[
          "load/accessorials/getChosenAccessorials"
        ];
        const lineItems = this.$store.getters["load/lineItems/getSelectedLineItems"];
        this.lineItemsMessageError = this.$refs.lineItems.returnLineItemsMessageError();
        const totalLimitsMessages = this.$store.getters[
          "load/lineItems/getLineItemsLimitMessage"
        ];
        this.lineItemsMessageError.push(...totalLimitsMessages);
        if (!this.$route.params.loadId) {
          await this.loadCreation(
            stopsValidation,
            lineItemsValidation,
            lineItemsLimitsValidation,
            validateCargoValue,
            stops,
            accessorials,
            lineItems,
            requote,
            ftlLimitsValidation
          );
        } else {
          await this.loadUpdate(
            stopsValidation,
            lineItemsValidation,
            lineItemsLimitsValidation,
            validateCargoValue,
            stops,
            accessorials,
            lineItems,
            requote,
            ftlLimitsValidation
          );
        }
      }
    },
    async loadCreation(
      stopsValidation,
      lineItemsValidation,
      lineItemsLimitsValidation,
      validateCargoValue,
      stops,
      accessorials,
      lineItems,
      requote,
      ftlLimitsValidation
    ) {
      this.creatingLoad = true;
      if (!ftlLimitsValidation) {
        this.creatingLoad = false;
        this.notifyWeightLimit();
        return;
      }
      // Validate missing fields
      if (
        stopsValidation &&
        lineItemsValidation &&
        validateCargoValue &&
        lineItemsLimitsValidation
      ) {
        this.$emit("creatingLoad", true);
        const load = {
          accessorials,
          stops,
          line_items: lineItems,
          load: {
            mode_id: this.modeInfo.modeId,
            customer_id: this.selectedCustomer?.id || process.env.VUE_APP_ACCOUNT_ID_TEST,
            multiservice: this.modeInfo.multiservice,
            cargo_value_for_insurance: this.cargoValue || null,
          },
          mode_id: this.modeInfo.modeId,
          load_status: "Quotes Received",
        };
        this.saveCommodityInLocalStorage(lineItems);
        try {
          const response = await this.$store.dispatch("load/createLoad", load);

          if (!response) {
            this.creatingLoad = false;
            this.$emit("creatingLoad", false);
            return;
          }

          await this.addCustomerInformationToIndexedDB(response.id);

          load.line_items = this.assignIdToCreatedLineItems(load.line_items, response.line_items);
          load.stops = this.assignIdToCreatedStops(stops, response.stops);
          load.name = response.load.name;

          this.$store.commit("load/setChosenLoad", load);
          await this.getLoadById(response.id);

          this.creatingLoad = false;
          this.changeView("CarriersInfo", response.id, requote);
        } catch (error) {
          this.creatingLoad = false;
          this.$emit("creatingLoad", false);
        }
      } else {
        this.showErrorMessage();
      }
      this.creatingLoad = false;
    },
    async loadUpdate(
      stopsValidation,
      lineItemsValidation,
      lineItemsLimitsValidation,
      validateCargoValue,
      stops,
      accessorials,
      lineItems,
      requote,
      ftlLimitsValidation
    ) {
      if (this.$options.filters.checkPermission("patch:load")) {
        if (!ftlLimitsValidation) {
          this.creatingLoad = false;
          this.notifyWeightLimit();
          return;
        }
        /* eslint-disable no-lonely-if */
        if (
          stopsValidation &&
          lineItemsValidation &&
          validateCargoValue &&
          lineItemsLimitsValidation
        ) {
          this.creatingLoad = true;
          this.$emit("creatingLoad", true);
          await this.handleUpdateRequests();
          // const newLineItems = await Promise.all(lineItemsRequests).then((response) => response);
          // Delete the collections
          this.refreshIndexedDBCollections();
          // Check if any stop changed
          const updatedChosenStops = [
            stops.find((stop) => stop.is_pickup),
            stops.find((stop) => stop.is_dropoff),
          ];
          for (let i = 0; i < stops.length; i += 1) {
            const currentStop = this.chosenLoad.stops.find(
              (stop) => stop.is_pickup === stops[i].is_pickup
            );
            if (this.validateUpdateStops(stops[i], currentStop)) {
              const data = {
                load_id: this.$route.params.loadId,
                stop_id: currentStop.id,
                body: stops[i],
              };
              if (!data.body.location_id) {
                data.body.contact_info_id = null;
              }
              if (this.$options.filters.checkPermission("patch:stop")) {
                const response = await this.$store.dispatch(
                  "load/stops/updateStop",
                  data
                );
                const newStop = response.stop;
                newStop.state_code = response.stop.state_province;
                newStop.zip_code = response.stop.postal_code;
                if (newStop.name.toLowerCase() === "delivery") {
                  updatedChosenStops[1] = newStop;
                } else {
                  updatedChosenStops[0] = newStop;
                }
              }
            } else if (
              stops[i].is_pickup &&
              this.validateUpdateExpectedDate(stops[i], currentStop)
            ) {
              // Check if expected date changed
              const dataExpectedDate = {
                load_id: this.$route.params.loadId,
                stop_id: this.chosenLoad.stops[i].id,
                body: {
                  expected_date: stops[i].expected_date,
                },
              };
              if (this.$options.filters.checkPermission("patch:stop")) {
                await this.$store.dispatch("load/stops/updateStop", dataExpectedDate);
                updatedChosenStops[0].expected_date = stops[i].expected_date;
              }
            }
          }
          // Update customer
          const updatePayload = {};
          if (
            this.enableCustomersSearcher &&
            this.selectedCustomer?.id !== this.chosenLoad.customer.id
          ) {
            updatePayload.customer_id =
              this.selectedCustomer?.id || process.env.VUE_APP_ACCOUNT_ID_TEST;
          }
          if (this.prevCargoValue !== this.cargoValue) {
            updatePayload.cargo_value_for_insurance = this.cargoValue || null;
          }
          if (Object.keys(updatePayload).length > 0) {
            await this.$store.dispatch("load/updateLoad", {
              id: this.$route.params.loadId,
              body: updatePayload,
            });
          }
          await this.updateModeId();
          if (this.selectedCustomer.id !== this.chosenLoad.customer.id) {
            await this.addCustomerInformationToIndexedDB(this.$route.params.loadId);
          }
          const newChosenLoad = await this.getLoadById(this.$route.params.loadId);
          this.$store.commit("load/setChosenLoad", newChosenLoad);
          this.creatingLoad = false;
          this.changeView("CarriersInfo", this.$route.params.loadId, requote);
        } else {
          this.showErrorMessage();
        }
        this.creatingLoad = false;
      }
    },
    refreshIndexedDBCollections() {
      this.$refs.lineItems.deleteIndexedDBInfo();
      db.deleteFromDb({
        collection: "lineItems",
        id: this.$route.params.loadId,
      });
      db.deleteFromDb({
        collection: "tenderReferences",
        id: this.$route.params.loadId,
      });
      db.deleteFromDb({
        collection: "tenderLineItems",
        id: this.$route.params.loadId,
      });
    },
    async addCustomerInformationToIndexedDB(loadId) {
      const customerInformation = await this.getCustomerInformationFromIndexedDB(loadId);
      if (customerInformation) {
        return db.updateDb({
          collection: "customerInformation",
          data: { loadId, ...this.selectedCustomer },
        });
      }
      return db.addToDb({
        collection: "customerInformation",
        data: { loadId, ...this.selectedCustomer },
      });
    },
    async getCustomerInformationFromIndexedDB(loadId) {
      return db.getFromDb({
        dbName: "quoteBook",
        collection: "customerInformation",
        id: loadId,
      });
    },
    async updateModeId() {
      const currentLoad = JSON.parse(
        JSON.stringify(this.$store.getters["load/getChosenLoad"])
      );
      if (
        this.modeInfo.multiservice !== currentLoad.multiservice ||
        this.modeInfo.modeId !== currentLoad.mode_id
      ) {
        const load = {
          mode_id: this.modeInfo.modeId,
          multiservice: this.modeInfo.multiservice,
        };
        await this.$store.dispatch("load/updateLoad", {
          id: this.$route.params.loadId,
          body: load,
        });
      }
    },
    async handleUpdateRequests() {
      const accessorialsRequests = this.$refs[
        this.accessorialsRef
      ].getUpdateAccessorialsRequest();
      if (this.$options.filters.checkPermission("patch:line-item")) {
        const updatedLineItems = this.$refs.lineItems.updateLineItemsToLoad();
        await Promise.all(updatedLineItems).then((response) => response);
      }
      if (this.$options.filters.checkPermission("delete:line-item")) {
        const deletedLineItems = this.$refs.lineItems.deleteLineItemsRequests();
        await Promise.all(deletedLineItems).then((response) => response);
      }
      await Promise.all(accessorialsRequests).then((response) => response);
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    assignLineItemId(lineItems, newLineItems) {
      return lineItems.map((el, index) => {
        const lineItem = { ...el };
        lineItem.line_item_id = newLineItems[index].line_item_id;
        return lineItem;
      });
    },
    assignIdToCreatedStops(createdStops, backendStops) {
      createdStops.forEach((stop) => {
        const foundStop = backendStops.find(
          (backendStop) => backendStop.name === stop.name
        );
        stop.id = foundStop.stop_id;
      });
      return createdStops;
    },
    assignIdToCreatedLineItems(lineItems, lineItemsWithId) {
      for (let i = 0; i < lineItems.length; i += 1) {
        lineItems[i].line_item_id = lineItemsWithId[i].id;
      }
      return lineItems;
    },
    showErrorMessage() {
      this.swal({
        title: `${this.$t("bookALoad.missingFields")}`,
        text: "Please, try again.",
        icon: "error",
        dangerMode: true,
      });
    },
    showErrorMessageHazmat() {
      this.swal({
        title: `${this.$t("bookALoad.missingFieldsHazmat")}`,
        text: "Please, try again.",
        icon: "error",
        dangerMode: true,
      });
    },
    checkLoadZipcode(type) {
      const stopNumber = type === "loadZipCode1" ? 0 : 1;
      this.$data[type] = this.chosenLoad.stops[stopNumber].zip_code;
      if (!this.$data[type]) {
        for (let i = 0; i < this.chosenLoad.accounts.length; i += 1) {
          if (
            /* eslint-disable operator-linebreak */
            this.chosenLoad.stops[stopNumber].location_id ===
            this.chosenLoad.accounts[i].id
          ) {
            this.$data[type] = this.chosenLoad.accounts[i].shipping_address.postal_code;
          }
        }
      }
    },
    saveCommodityInLocalStorage(lineItems) {
      const savedCommodities = JSON.parse(localStorage.getItem("commodities")) || [];
      const newCommodities = lineItems.map((lineItem) => lineItem.description.trim());
      const commodities = savedCommodities.concat(newCommodities);
      localStorage.setItem(
        "commodities",
        JSON.stringify(Array.from(new Set(commodities)))
      );
    },
    changeView(routeName, loadId, requote) {
      this.$router
        .push({
          name: routeName,
          params: {
            loadId,
            ...(requote && {
              requote: true,
            }),
            socket: true,
          },
        })
        .catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },
    validateCargoValue() {
      if (this.cargoValue < 0) {
        this.invalidCargoValue = true;
        return false;
      }
      this.invalidCargoValue = false;
      return true;
    },
    notifyWeightLimit() {
      let number;
      if (this.modeInfo.id === "ftl") {
        number = 44.000;
      } else if (this.modeInfo.id === "reefer") {
        number = 43.500;
      }
      this.swal({
        title: `Weight limit exceeded`,
        text: `Total weight should not exceed ${number} pounds`,
        icon: "error",
        dangerMode: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Modal.scss";

.row-stops {
  margin-top: 25px;
}

.lineitems-container {
  margin-top: 20px;
}

.create-load-content {
  &__title {
    color: $color-primary-company;
    font-size: 12px;
    font-style: italic;
    font-family: $font-family-portal;
    text-align: left;
  }

  hr {
    background-color: $color-border-container;
  }
}

.create-load-overlay {
  color: $color-primary-company;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-quote {
  border-radius: 15px;
  margin-right: 10px;
  width: 25%;
  border: none;
  background-color: $color-primary-trigger-button;
  color: $color-white;
  height: 40px;
  font-weight: bold;
  @include font-button-text;

  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 20px auto 0px auto;
  }
}

::v-deep .b-overlay-wrap {
  width: 100%;
}

.create-load-icon {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.cargo-value {
  width: 350px;
}

@media (max-width: 560px) {
  .create-load {
    margin: 3%;
  }
  .cargo-value {
    width: 100%;
  }
}

::v-deep .bg-light {
  inset: -13px 0px 0px 0px;
  background-color: $color-gray-light !important;
}

::v-deep {
  @media (max-width: 800px) {
    .floatlabel-input__label {
      font-size: 0.875rem;
    }
    .search-input__input {
      font-size: 0.875rem;
    }
    .dropdown__option {
      font-size: 0.875rem;
    }
    .dropdown-container .label-container {
      font-size: 0.875rem;
    }
    .dropdown-container__selected-option {
      font-size: 0.875rem;
      height: 100%;
    }
    .floatlabel {
      font-size: 0.74rem !important;
    }
  }
}

::v-deep {
  .rc-searcher-container {
    margin: 0px;
    border: 1px solid $color-border-container;
    border-radius: 10px;
    &:hover,
    &:focus-within {
      box-shadow: none;
    }
  }
}
</style>
