<template>
    <div class="Hazardous-selector-container">
      <RCSelect
        :suggestions="HazardousOptions"
        v-model="selectedHazardousOption"
        label="Select Hazmat"
        @change="handleHazardousSelection"
        class="Hazardous-selector"
      />
    </div>
  </template>

<script>
import RCSelect from "@/components/RCComponents/CustomRCSelect.vue";
import { HazardousOptions } from "@/utils/DrayageDetails";

export default {
  name: "HazardousSelector",
  components: {
    RCSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedHazardousOption: this.value,
      HazardousOptions,
    };
  },
  watch: {
    selectedHazardousOption(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    handleHazardousSelection(option) {
      this.$emit("change", option);
    },
  },
};
</script>

  <style scoped>
  .Hazardous-selector-container {
    margin-left: 10px;
    width: 150px;
  }

  </style>
