var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-items-drayage"},_vm._l((_vm.selectedLineItems),function(option,index){return _c('section',{key:index,staticClass:"lineitem-container",attrs:{"id":'line-item' + index}},[_c('div',{staticClass:"lineitem-row"},[_c('div',{staticClass:"type-container lineitem-inputs"},[_c('RCSelect',{key:_vm.renderKey,staticClass:"type-container__input",class:{
            'missing-field': _vm.errors['type_container'],
            'disabled': _vm.disabled
          },attrs:{"label":"Type Container","floatLabel":true,"requiredField":true,"suggestions":_vm.options},model:{value:(option.type_container),callback:function ($$v) {_vm.$set(option, "type_container", $$v)},expression:"option.type_container"}})],1),_c('ul',{staticClass:"weight lineitem-inputs"},[_c('li',{staticClass:"weight__input"},[_c('RCInput',{class:{
              'missing-field': _vm.errors['weight'],
              'disabled': _vm.disabled
            },attrs:{"label":_vm.$t('allOptions.weight'),"floatLabel":true,"positiveNumbers":true,"requiredField":true,"type":"number"},model:{value:(option.weight),callback:function ($$v) {_vm.$set(option, "weight", $$v)},expression:"option.weight"}})],1),_c('li',{staticClass:"weight__dropdown"},[_c('RCSelect',{class:{
              'missing-field': _vm.errors['weight_units'],
              'disabled': _vm.disabled
            },attrs:{"suggestions":_vm.weight_units,"label":_vm.$t('allOptions.weightUnits')},model:{value:(option.weight_units),callback:function ($$v) {_vm.$set(option, "weight_units", $$v)},expression:"option.weight_units"}})],1)]),_c('div',{staticClass:"description lineitem-inputs"},[_c('RCInput',{class:{
            'missing-field': _vm.errors['description'],
            'disabled': _vm.disabled
          },attrs:{"label":_vm.$t('allOptions.commodity'),"floatLabel":true,"type":'text',"requiredField":true},model:{value:(option.description),callback:function ($$v) {_vm.$set(option, "description", $$v)},expression:"option.description"}})],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }