/* eslint-disable max-len */
import * as yup from "yup";

const LineItemDrayageSchema = yup.object().shape({
  type_container: yup.string().required(),
  weight_units: yup.string().required(),
  weight: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    ),
  description: yup.string().required(),
});

export default LineItemDrayageSchema;
