<template>
  <div class="stops">
    <div class="stops-container">
      <StopSelector
        v-model="delivery"
        :salesforceAccounts="stopsSalesforceAccounts"
        :chosenLoad="chosenLoad"
        :isDrayage="true"
        :stopInformation="delivery"
        type="delivery"
        ref="delivery-stop"
        @input="handleStopChange"
      />
    </div>
    <div class="stops-container">
      <StopSelector
        v-model="pickup"
        :chosenLoad="chosenLoad"
        :isPortStop="true"
        :deliveryValue="delivery"
        :miles="milesValue"
        :isDrayage="true"
        :enabledStopFromDrayage="enabledPortsStop"
        :stopInformation="pickup"
        type="pickup"
        ref="pickup-stop"
        @input="handleStopChange"
      />
    </div>
  </div>
</template>

<script>
import StopSelector from "../LoadCreation/stops/StopSelector.vue";

export default {
  name: "DrayageStops",
  components: {
    StopSelector,
  },
  props: {
    chosenLoad: Object,
    milesValue: Number
  },
  data() {
    return {
      reassigPickup: {
      },
      pickup: null,
      delivery: null,
      stopsSalesforceAccounts: null,
    };
  },
  computed: {
    enabledPortsStop() {
      return !!this.delivery;
    }
  },
  watch: {
    chosenLoad: {
      handler(value) {
        this.assignStops(value);
      },
      deep: true,
    }
  },
  async created() {
    const dataSalesforceAccounts = {
      params: {
        account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
        skip: 0,
        limit: 1000,
      },
    };
    this.stopsSalesforceAccounts = await this.$store.dispatch(
      "load/stops/getSalesforceAccounts",
      dataSalesforceAccounts
    );
  },
  methods: {
    validateFormFields() {
      const pickupValidation = this.$refs["pickup-stop"].validateFormfields();
      const deliveryValidation = this.$refs["delivery-stop"].validateFormfields();
      if (pickupValidation && deliveryValidation) {
        return true;
      }
      return false;
    },
    handleStopChange() {
      if (this.pickup) {
        this.reassigPickup.zip_code = this.pickup?.postal_code || this.pickup?.stopData?.postal_code;
        this.reassigPickup.state_code = this.pickup?.state || this.pickup?.stopData?.state;
        this.reassigPickup.city = this.pickup?.city || this.pickup?.stopData?.city;
        this.reassigPickup.terminal_code = this.pickup?.terminal_code || this.pickup?.stopData?.terminal_code;
        this.reassigPickup.name = this.pickup?.name || this.pickup?.stopData?.name;
        this.reassigPickup.country_code = this.pickup?.country_code || this.pickup?.stopData?.country_code;
        this.reassigPickup.location_id = this.pickup?.location_salesforce_id || this.pickup?.stopData?.location_salesforce_id;
        this.reassigPickup.contact_info_id = this.pickup?.contact_salesforce_id || this.pickup?.stopData?.contact_salesforce_id;
        this.reassigPickup.number = 0;
        this.reassigPickup.is_pickup = true;
      }

      this.$emit('updateStops', {
        pickup: this.reassigPickup || null,
        delivery: this.delivery || null
      });
    },
    assignStops(value) {
      if (value) {
        this.pickup = value?.stops?.find((stop) => stop.is_pickup) || this.pickup;
        this.delivery = value?.stops?.find((stop) => stop.is_dropoff) || this.delivery;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.stops {
  @extend %flex-row-evenly;
  justify-content: space-between;
}

.stops-container {
  width: 48%;
  padding: 10px 0px;
}

///****Responsive styles****\\\\
@media (max-width: 800px) {
  .stops {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-container {
      width: 100%;
      padding: 10px 0px;
    }
  }
}
@media (max-width: 330px) {
  .stops {
    margin-top: 5%;
  }
}
</style>
